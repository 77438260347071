import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/IndexView.vue"),
  },
  {
    path: "/index",
    name: "SelectVillage",
    component: () => import("@/views/SelectVillage.vue"),
    meta: { title: '选择村庄' }
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/RegisterView.vue"),
  },
  {
    path: "/articleview",
    name: "ArticleView",
    component: () => import("@/views/ArticleView.vue"),
  },
  {
    path: "/articlelist",
    name: "ArticleList",
    component: () => import("@/views/ArticleList.vue"),
  },
  {
    path: "/noticeview",
    name: "NoticeView",
    component: () => import("@/views/NoticeView.vue"),
  },
  {
    path: "/mobileview",
    name: "MobileView",
    component: () => import("@/views/MobileView.vue"),
  },
  {
    path: "/proposalview",
    name: "ProposalView",
    component: () => import("@/views/ProposalView.vue"),
  },
  {
    path: "/proposallist",
    name: "ProposalList",
    component: () => import("@/views/ProposalList.vue"),
  },
  {
    path: "/postview",
    name: "PostView",
    component: () => import("@/views/PostView.vue"),
  },
  {
    path: "/postlist",
    name: "PostList",
    component: () => import("@/views/PostList.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
